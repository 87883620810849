// @use "../../../scss/" as *;
@font-face {
    font-family: 'OpenSansBold';
    src: url('../assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}


/*BOARD*/

* {
    font-family: 'OpenSansBold';
}

#game-content {
    // background-color: blue;
    height: 100%;
    width: 100%;
    min-width: 500px;
    padding: 2vh;
    background-image: url('../assets/img/music_player_bg.gif');
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 50vh;
    -webkit-background-size: 50vh;
    -moz-background-size: 50vh;
    -o-background-size: 50vh;
    // display: flex;
    // flex-direction: column;
}

.score-section {
    // margin-top: 2vh;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    .namePlayer {
        color: white;
        margin: 0 2vh 2vh 0;
        padding-left: 2vh;
        padding-right: 2vh;
        padding-top: 0.2vh;
        padding-bottom: 0.2vh;
        text-align: center;
        font-size: 1.8rem;
        font-weight: bolder;
        font-style: oblique;
        border-radius: 3rem;
        min-width: 10rem;
        max-width: 14rem;
    }
}

// .shiny-bg {
//     border: 1mm ridge rgba(255, 255, 255, 0.7);
// }

/* La board en haut à gauche */

#music-player {
    // background-color: red;
    height: 70%;
    margin: auto;
    margin-top: 5rem;
    text-align: center;
    align-items: center;
    padding: 0;
}

#messageViewer {
    height: 10%;
    margin: 0;
    font-size: 3rem;
    font-weight: 600;
}

#timer {
    height: 43%;
    margin: 0;
    font-size: 3.5rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#logo-center {
    width: 30vh;
}

#controller {
    position: absolute;
    width: 80vw;
    height: 80vh;
    top: 10vh;
    left: 10vw;
    right: 10vw;
    display: flex;
}

#message {
    color: #111;
    text-align: center;
    margin: 0
}