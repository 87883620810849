.hero-slide__item {
    &.active>&__content>&__content__info {
        .btns,
        .title,
        .features,
        .overview {
            opacity: 1;
            transform: translateY(0);
        }
        .title {
            transition-delay: 0.1s, 0.1s;
        }
        .overview {
            transition-delay: 0.2s, 0.2s;
        }
        .features {
            transition-delay: 0.25s, 0.25s;
        }
        .btns {
            transition-delay: 0.4s, 0.4s;
        }
    }
}