.options {
    .option {
        // background-color: red;
        margin: 2rem 1rem;
        padding: 1rem 0rem 1rem 2rem;
        align-items: center;
        display: flex;
        width: 80vw;
        .icon {
            width: 30%;
            vertical-align: middle;
        }
        .optionTitle {
            font-size: 3rem;
            vertical-align: middle;
        }
    }
}