.container {
    position: absolute;
    right: 0rem;
    top: 0rem;
    border-radius: 0% 0% 0% 70%;
    width: 9rem;
    height: 9rem;
    .content {
        position: relative;
        .icon {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
        }
    }
}