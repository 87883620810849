:root {
    --main-bg-color: #080219;
    --main-text-color: #eee;
    --unboared-primary: #ff2453;
    --unboared-secondary: #f3ca20;
    --unboared-tercary: #1bc65f;
}

.base-timer {
    justify-content: center;
    position: relative;
    width: 20vh;
    height: 20vh;
    top: 3vh;
}

.base-timer__svg {
    transform: scaleX(-1);
}

.base-timer__circle {
    fill: none;
    stroke: none;
}

.base-timer__path-elapsed {
    stroke-width: 4px;
    stroke: var(--main-text-color);
}

.base-timer__path-remaining {
    stroke-width: 4px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
}

.base-timer__path-remaining.green {
    color: var(--unboared-tercary);
}

.base-timer__path-remaining.orange {
    color: var(--unboared-secondary);
}

.base-timer__path-remaining.red {
    color: var(--unboared-primary);
}

.base-timer__label {
    position: absolute;
    width: 20vh;
    height: 20vh;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}