        // @font-face {
            // font-family: 'JungleFont';
        //     src: url('../../assets/fonts/KomikaTitle.ttf') format('truetype');
        // }
        .jungle_speed {
            &__container {
                height: 100%;
                width: 100%;
                min-width: 500px;
                background-position-x: center;
                background-repeat: no-repeat;
                * {
                    font-family: 'KomikaTitle';
                    color: #111;
                }
                h1 {
                    font-size: 2.5vh;
                    font-weight: bold;
                }
                h2 {
                    margin: 0.9vh 0;
                    font-size: 1.9vh;
                    font-weight: 600;
                }
                h3 {
                    font-size: 1.6vh;
                    font-weight: bolder;
                    opacity: 0.7;
                }
                /*BOARD*/
                #board {
                    height: 80vh;
                    width: 60vw;
                    min-width: 500px;
                    align-items: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    /* background-color: teal; */
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    justify-content: center;
                }
                /* La board en haut à gauche */
                .namePlayer {
                    font-size: 1.4rem;
                    color: black;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-top: 0.1rem;
                    padding-bottom: 0.1rem;
                    text-align: center;
                    size: 2.5vh;
                    font-weight: bolder;
                    font-style: oblique;
                    border-radius: 2rem;
                    box-shadow: 0 6px 3px -3px black;
                }
                
                .activePlayer {
                    // box-shadow: 0 0 12px 10px #ff2453a8;
                    box-shadow: 0 0 12px 10px rgba(240, 240, 240);
                }
                #board-1 {
                    position: absolute;
                    top: 10vh;
                    left: 10vh;
                    align-items: center;
                    /* justify-content: space-between; */
                }
                /* La board en bas à gauche */
                #board-2 {
                    position: absolute;
                    bottom: 10vh;
                    left: 10vh;
                    align-items: center;
                    /* justify-content: space-between; */
                }
                /* La board en haut à droite */
                #board-3 {
                    position: absolute;
                    bottom: 10vh;
                    right: 10vh;
                    align-items: center;
                    /* justify-content: space-between; */
                }
                /* La board en haut à droite */
                #board-4 {
                    position: absolute;
                    top: 10vh;
                    right: 10vh;
                    align-items: center;
                    /* justify-content: space-between; */
                }
                .board-totem {
                    z-index: 1;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    .logo-totem-center {
                        height: 17rem;
                        width: 17rem;
                        // background-color: black;
                        background-image: url('../../assets/img/Totem.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
                #cardCenter1 {
                    background-image: url('../../assets/img/CardMark.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 12rem;
                    width: 12rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                #cardCenter2 {
                    background-image: url('../../assets/img/CardMark.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 12rem;
                    width: 12rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                #cardCenter3 {
                    background-image: url('../../assets/img/CardMark.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 12rem;
                    width: 12rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                #cardCenter4 {
                    background-image: url('../../assets/img/CardMark.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 12rem;
                    width: 12rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                #my-canvas {
                    position: absolute;
                    top: 0;
                    left: 20vw;
                }
                #controller {
                    position: absolute;
                    width: 80vw;
                    height: 80vh;
                    top: 10vh;
                    left: 10vw;
                    right: 10vw;
                    display: flex;
                }
                #throw_card {
                    margin: 5%;
                    width: 50%;
                    height: 100%;
                    background: url('../../assets/img/cards/Cartes_Verso.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
                #throw_disc {
                    margin: 5%;
                    width: 50%;
                    height: 100%;
                    background: url('../../assets/img/controller/Attraper_Button.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
                #messageViewer {
                    position: absolute;
                    top: 40vh;
                    left: 45vw;
                    margin: auto;
                    // height: 100vh;
                    // width: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                #popMessage {
                    z-index: 10;
                    height: 21.6vh;
                    width: 20vh;
                    background: url('../../assets/img/popMessageBg.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                #imgWinner {
                    height: 6.5vh;
                    width: 6.5vh;
                }
                #message {
                    font-size: 2.5vh;
                    color: #111;
                    text-align: center;
                    margin: 0
                }
                #main-menu {
                    /* background-color: chartreuse; */
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                }
                /*ALERT SCREEN*/
                #alertMessage {
                    height: 100vh;
                    width: 100vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                #logo-alert {
                    width: 25vh;
                    height: 25vh;
                    margin: 5vh
                }
                .alertText {
                    padding: 0 2vh;
                    text-align: center;
                }
                .score-table {
                    position: absolute;
                    width:10%;
                    height:25%;
                    top : 15%;
                    left:5%;
                    transform: translate(-5%,-15%);
                    img {
                        height:100%;
                        width:100%;
                    }
                    .scores {
                        position: absolute;

                        top:50%;
                        left:50%;
                        height:90%;
                        width:90%;
                        transform: translate(-50%,-50%);
                        
                        h3 {
                            margin:1rem;
                            font-size:1.8vh;
                        }
                    }
                }
            }
        }