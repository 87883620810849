.container {
    text-align: center;
    .connexionBody {
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        * {
            display: ruby;
            margin-bottom: 1rem;
            font-size: 2.5rem;
            font-weight: 500;
        }
        .instructions {
            display: flex;
            flex-direction: column;
            * {
                font-size: 1.8rem;
            }
        }
        .avatarSelection {
            margin-bottom: 3rem;
        }
        input {
            margin-bottom: 1.5rem;
            font-weight: 700;
            padding: 1rem 1.5rem;
        }
    }
}
.header {
    font-size: 4rem;
}